import store from '../store'


export function getUploadPercentage() {
	return store.state.req.status.uploadProgress;
}	
export function setUploadPercentage(percentage) {
	store.state.req.status.uploadProgress = percentage;
}	


export function testUploadProgress(i){
	if(store.state.req.status.uploadProgress < 100){
		store.state.req.status.uploadProgress += i;
		setInterval(() => {
			testUploadProgress(i);
		}, 1000);
		
	}
}