<template>

  <div class="container-fluid pt-2 pe-2 pb-1 ps-4 mb-3">
    <div class="row ">
      <div class="col-6 col-xs-6 col-md-5 col-lg-4 border-end border-primary avatar-col">
        <div class="avatar-item ps-5 pt-3 pb-3 d-flex align-items-stretch justify-content-stretch">
          <span id="avatar-shortname" class="avatar-image d-flex align-items-center justify-content-center">{{ userInitials }}</span>
          <div class="avatar-name d-flex align-items-center justify-content-end">
            <span id="avatar-username" class="ps-2">{{ userName }}</span>
            <a @click="logout()" class="avatar-logout cursor-hand">{{ $t('messages.general.logout') }} <svg xmlns="http://www.w3.org/2000/svg" width="18"
                                                                               height="20" viewBox="0 0 18 20">
              <path id="Path_logout" data-name="Path / logout"
                    d="M16,17V14H9V10h7V7l5,5-5,5M14,2a2,2,0,0,1,2,2V6H14V4H5V20h9V18h2v2a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V4A2,2,0,0,1,5,2Z"
                    transform="translate(-3 -2)" fill="#929292" />
            </svg></a>
          </div>
        </div>
      </div>
      <div class="col-6 col-xs-6 col-md-4 col-lg-4  d-flex align-self-center justify-self-start">
        <span class="serviceportal-text text-primary ms-4 mb-0 h1 awesome align-self-end">Serviceportal</span>
      </div>
      <div class="col-6 col-xs-6 col-md-3 col-lg-4  d-flex align-items-center justify-content-end">
        <div class="partner-logo">
          <img :src="getOrganizationImageUrl()" alt="" width="250"
               class="d-inline-block align-text-top" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      userName:'',
      organizationId:0,
      userInitials:'NN'
    }
  },
  methods: {
    getOrganizationImageUrl(){
      let img = null;
      try {
        img = require('@/assets/img/org-'+this.organizationId+'.png');
      } catch (error) {
        img = require('@/assets/img/org-def.png');
      }
      return img;
    },
    logout(){
      var logoutOptions = { redirectUri :  process.env.VUE_APP_AUTH_REDIRECT_URL};
      this.myKeycloak.logout(logoutOptions).then((success) => {
        localStorage.removeItem('user');
      }).catch((error) => {
        localStorage.removeItem('user');
      });
    }
  },
  created() {
    if(this.myKeycloak && this.myKeycloak.hasOwnProperty.call(this.myKeycloak, 'idTokenParsed') ){
      if(this.myKeycloak.hasOwnProperty.call(this.myKeycloak.idTokenParsed,'name')) {
        this.userName = this.myKeycloak.idTokenParsed.name;
        this.userInitials = (fullname=>fullname.map((n, i)=>(i==0||i==fullname.length-1)&&n[0]).filter(n=>n).join(""))((this.myKeycloak.idTokenParsed.name).split(" "));
      }
      if(this.myKeycloak.hasOwnProperty.call(this.myKeycloak.idTokenParsed,'organizationId')) {
        this.organizationId = this.myKeycloak.idTokenParsed.organizationId;
      }
    }
  }

}

</script>

<style>
</style>
