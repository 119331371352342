import {
	createStore
} from "vuex";
import {
	auth
} from "./auth.module";
import {
	req
} from "./req.module";
import {getTranslatedCountries, getTranslatedServiceRequestTypes} from "@/helpers";



const store = createStore({
	state: {
		listLayout: false,
		serviceItemCount: 0,
		listDefaultIncludes: 'creator;address;subsidiary:address;order;order:workUnitActivities;service_request_invoice_files;service_request_device_report_files;service_request_delivery_note_files;canceller',
		allServices: null,
		services: null,
		usedFilterItems:  {},
		dateFrom: null,
		dateTo: null,
		selectedUserId: 0,
		selectedUserName: '',
		filterListClass: 'all',
		selectedRequestTypeName: '',
		selectedRequestTypeId: 0,
		selectedSubsidiaryName: '',
		selectedSubsidiaryId: 0,
		searchMode: 'customerName',
		searchField: '',
		reload: false,
		lastUpdate: 0,
		routeName: '',
		countByStatus: {
			isCancelled: 0,
			isClosed: 0,
			isPlanned: 0,
			isNew: 0,
			inProcess: 0
		},
		today: new Date((new Date()).setHours(0, 0, 0, 0)).getTime(),
		tomorrow: new Date((new Date(new Date().getTime() + (24 * 60 * 60 * 1000))).setHours(0, 0, 0, 0))
			.getTime(),
		nextWorkday:new Date((new Date(new Date().getTime() + (24 * 60 * 60 * 1000))).setHours(0, 0, 0, 0))
			.getTime(),
		statusList: {
			isCancelled: {
				text: 'Storniert',
				class: 'cancelled'
			},
			isClosed: {
				text: 'Abgeschlossen',
				class: 'closed'
			},
			isPlanned: {
				text: 'Geplant',
				class: 'planned'
			},
			isNew: {
				text: 'Neu',
				class: 'new'
			},
			inProcess: {
				text: 'in Bearbeitung',
				class: 'processed'
			},
		},
	},
	mutations: {
		SET_LANGUAGE_PARAMS(state,params) {
			req.state.countries = getTranslatedCountries(req.state.countries);
			req.state.serviceRequestTypes = getTranslatedServiceRequestTypes(req.state.serviceRequestTypes);
			req.state.language = params.lang;
			req.state.translations = params.translations;
		}
	},
	actions: {

	},
	modules: {
		auth,
		req,
	},
});

export default store;
