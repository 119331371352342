<template>
	<div class="bg-primary text-white footer-info pt-3 pb-3">
		<div class="container">
			<div class="row">
				<div class="col col-lg-6 help">
					<!--<a href="hilfe" class="text-white">Help</a> <span class="divider">|</span> <a href="faq"
						class="text-white">FAQ</a> <span class="divider">|</span>-->
					Hotline <span class="ms-2 pe-7s-mail"> </span> <a href="mailto:triport-support@triview.cloud"
						class="text-white">triport-support@triview.cloud</a>

				</div>
				<div class="col col-lg-6 imprint">
          <a href="https://triview.cloud/imprint" class="text-white" target="_blank">{{ $t('messages.pages.imprint') }}</a> <span class="divider">|</span>
          <a href="https://triview.cloud/dataprotection" class="text-white" target="_blank">{{ $t('messages.pages.dataProtection') }}</a> <span class="divider">|</span>
          <router-link :to="{name: 'AGB',hash: '#top'}" class="text-white">{{ $t('messages.pages.gtc') }}</router-link>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
</script>

<style>

</style>
