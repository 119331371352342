
import * as progressViewer from '../helpers/uploadProgress.js';
import noAuthAxios from '../services/axios.default.js';
import authAxios from '../services/axios.auth.js';



//LISTE SERVICE-ITEMS
/*
Parameter erlaubt:
limit=10 //Anzahl maximale Einträge
pageOrdId=[id] //Starte mit Eintrag [id]
includes=address;subsidiary;creator;files; 
deepIncludes möglich: includes=address:country (übergibt den Ländereintrag mit)
Default-Includes sind in store > index definiert;
listDefaultIncludes: 'creator;address;subsidiary:address;order;clerkContact;order:workUnitActivities',
*/


const LIST_ENDPOINT = 'service_requests/';

//EINTRAG ZUM LÖSCHEN MARKIEREN
const CANCEL_SERVICE_ITEM_ENDPOINT = 'service_requests/[service_id]/cancel/[canceller_id]';

//country
const COUNTRIES_ENDPOINT = 'countries/';

//SERVICE-ITEMS über DeliveryCustomerNumber
const DELIVERY_CUSTOMER_NUMBER_ENDPOINT = 'service_requests/by_delivery_customer_number/[deliveryCustomerNumber]';

//Service-Dienstleister in der Nähe
//Übergabe Parameter PLZ und Ort: deliveryPostCode UND deliveryPlace
const SUBSIDIARIES_DISTANCE_ENDPOINT = 'service_requests/work_unit_distances/[deliveryPlace]/[deliveryPostcode]';

//Mandanten aus bisherigen Eingaben
const SUBSIDIARIES_ENDPOINT = 'service_requests/used_subsidiaries/';

const CLERK_ENDPOINT = 'clerks/contacts/bySubsidiary/[subsidiary_id]';

//Ersteller Endpoint
const USERS_ENDPOINT = 'service_requests/users';

//Adressen über Postleitzahl
const ADDRESSES_ENDPOINT = 'service_requests/addresses';

//Serice-Arten Störung etc.
const SERVICE_REQUEST_TYPES_ENDPOINT = 'service_request_types/';

//Serice-Arten Störung etc.
const SERVICE_REQUEST_INVOICE_FILES = 'service_request_invoice_files/';

//Serice-Arten Störung etc.
const SERVICE_REQUEST_REPORT_FILES = 'service_requests/[service_id]/device_reports/[file_id]';

//Lieferscheine ohne ID, gibt alle zurück
const SERVICE_REQUEST_DELIVERY_NOTE_FILES = 'service_requests/[service_id]/delivery_notes';


//Mandanten aus bisherigen Eingaben
const INFO_MACHINE_ENDPOINT = 'service_requests/by_machine_serial_number/[searchstring]';

//Kundenreferenznummer abfragen
const CUSTOMER_REFERENCE_ENDPOINT = 'service_requests/by_customer_reference/[searchstring]';

const PLACE_NAME_FROM_POSTAL_CODE_NAME_ENDPOINT = 'https://zip-api.eu/api/v1/codes/postal_code=[iso2_country_code]-[postal_code]';

const POSTAL_CODE_FROM_PLACE_NAME_ENDPOINT = 'https://zip-api.eu/api/v1/codes/place_name=[iso2_country_code]-[place_name]';

class DataService {
	getQueryStringFromParams(params) {
		let str = [];
		if (params) {
			for (let p in params) {
				if (p == 'includes' && params[p].indexOf(';') > -1) {
					let includesAll = params[p].split(';');
					includesAll.forEach(function(obj) {
						if (obj.trim() != '') str.push(encodeURIComponent(p) + "=" + obj);
					});
				}
				else if (p == 'ids'){
					let includesAll = params[p].split(';');
					includesAll.forEach(function(obj) {
						if (obj.trim() != '') str.push(encodeURIComponent(p) + "=" + obj);
					});
				}
				else if (p == 'limit' || p == 'pageOrdId') {
					str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[
						p]));
				}
				else {
					str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[
						p]));
				}
			}
			return '?' + str.join("&");
		} else {
			return '';
		}
	}
	handleErrors(error) {
		let answer = {
			status: 1,
			message: "",
			statusText:"",
			responseUrl:null,
			error:true,
			messageType:"unknown"
		};

		if(typeof error === 'object'){
			answer.messageType = "object";
			if(Object.prototype.hasOwnProperty.call(error,'message')){
				answer.message = error.message;
			}
			if(Object.prototype.hasOwnProperty.call(error,'response')){
				if(Object.prototype.hasOwnProperty.call(error.response,'status')){
					answer.status = error.response.status;
				}
				if(Object.prototype.hasOwnProperty.call(error.response,'statusText')){
					answer.statusText = error.response.statusText;
				}
			}
			if(Object.prototype.hasOwnProperty.call(error,'request')){
				if(Object.prototype.hasOwnProperty.call(error.request,'responseURL')){
					answer.responseURL = error.request.responseURL;
				}
			}
		}
		else if(typeof error === 'string'){
			answer.messageType = "string";
			answer.message = error;
		}

		return answer;
	}

	async handleAuthGetRequest(endpoint, params) {
		try {
			let response = await authAxios.get(endpoint + this.getQueryStringFromParams(params) , {});
				return {
					'error': false,
					'data': response.data,
					'status': response.status
				};


		} catch (error) {
			return this.handleErrors(error);
		}
	}

	async handleNoAuthGetRequest(endpoint, params) {
		try {
			let response = await noAuthAxios.get(endpoint + this.getQueryStringFromParams(params) , {});
			return {
				'error': false,
				'data': response.data,
				'status': response.status
			};
		} catch (error) {

			return this.handleErrors(error);
		}
	}



	async handlePostRequest(endpoint, data, params) {
		try {
			let response = await authAxios.post(endpoint + this.getQueryStringFromParams(params),
				data, {
					onUploadProgress: (progressEvent) => {
							let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
							progressViewer.setUploadPercentage(percentCompleted);
					}
				});
			return {
				'error': false,
				'data': response.data,
				'status': response.status
			};
		} catch (error) {
			return this.handleErrors(error);
		}
	}


	cancelServiceItem(params) {
		return this.handlePostRequest(CANCEL_SERVICE_ITEM_ENDPOINT.replace('[service_id]', params.serviceId).replace('[canceller_id]', params.cancellerId));
	}
	getSubsidiariesList() {
		return this.handleAuthGetRequest(SUBSIDIARIES_ENDPOINT);
	}
	getServiceRequestTypes() {
		return this.handleAuthGetRequest(SERVICE_REQUEST_TYPES_ENDPOINT);
	}
	getCountries() {
		return this.handleAuthGetRequest(COUNTRIES_ENDPOINT);
	}
	getServiceItemsList(params) {
		return this.handleAuthGetRequest(LIST_ENDPOINT, params);
	}
	getDeliveryCustomersByNumber(searchstring) {

		return this.handleAuthGetRequest(DELIVERY_CUSTOMER_NUMBER_ENDPOINT.replace('[deliveryCustomerNumber]', searchstring));
	}
	/*Alle Ansprechpartner Daten holen*/
	getClerkList(params) {
		return this.handleAuthGetRequest(CLERK_ENDPOINT.replace('[subsidiary_id]', params.subsidiaryId));
	}
	/*Alle Ersteller Daten holen*/
	getUserList() {
		return this.handleAuthGetRequest(USERS_ENDPOINT);
	}
	/*Adressen über PLZ suchen*/
	getAddressListByPostCode(params) {
		return this.handleAuthGetRequest(ADDRESSES_ENDPOINT, params);
	}

	/*Service-Dienstleister über PLZ und Ort holen*/
	getSubsidiariesByDistance(params) {
		return this.handleAuthGetRequest(SUBSIDIARIES_DISTANCE_ENDPOINT.replace('[deliveryPlace]', params.deliveryPlace).replace('[deliveryPostcode]', params.deliveryPostCode));
	}

	/*Service-Item laden*/
	getServiceItem(params) {
		return this.handleAuthGetRequest(LIST_ENDPOINT, params);
	}

	/*Service-Item laden*/
	getReportFiles(request_id, file_id, params) {
		return this.handleAuthGetRequest(SERVICE_REQUEST_REPORT_FILES.replace('[service_id]', request_id).replace('[file_id]', file_id), params);
	}

	/*Service-Item laden*/
	getDeliveryFiles(request_id, file_id, params) {
		return this.handleAuthGetRequest(SERVICE_REQUEST_DELIVERY_NOTE_FILES.replace('[service_id]', request_id), params);
	}

	/*Service-Item laden*/
	getInvoiceFiles(id, params) {
		return this.handleAuthGetRequest(SERVICE_REQUEST_INVOICE_FILES + id, params);
	}
	getServiceItemsByInfoMachine(searchstring){
		return this.handleAuthGetRequest(INFO_MACHINE_ENDPOINT.replace('[searchstring]', searchstring));
	}

	getServiceItemsByCustomerReference(searchstring){
		return this.handleAuthGetRequest(CUSTOMER_REFERENCE_ENDPOINT.replace('[searchstring]', searchstring));
	}
	/*Neuen Service-Item anlegen*/
	postServiceItem(data) {
		return this.handlePostRequest(LIST_ENDPOINT, data);
	}

	getPlaceNamesByPostalCode(params){
		return this.handleNoAuthGetRequest(PLACE_NAME_FROM_POSTAL_CODE_NAME_ENDPOINT.replace('[iso2_country_code]', params['iso2CountryCode']).replace('[postal_code]', params['postalCode']+'*'));
	}
	getPostalCodesByPlaceName(params){
		return this.handleNoAuthGetRequest(POSTAL_CODE_FROM_PLACE_NAME_ENDPOINT.replace('[iso2_country_code]', params['iso2CountryCode']).replace('[place_name]', params['placeName']+'*'));
	}
}

export default new DataService();
