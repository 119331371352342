import store from '../store'

//Für Feiertage Prüfung für die Stornierung
let hcheck = require("german-holiday")

export function setNextWorkday(today) {
	//Ist es ein Feiertag oder Wochenende, dann eins hochsetzen
	let nextDay = new Date(today.getTime() + (24 * 60 * 60 * 1000));
	if (
		today.getDay() % 6 === 0 ||
		hcheck.holidayCheck(nextDay, "BY")  ||
		nextDay.getDay() % 6 === 0 ||
		hcheck.holidayCheck(nextDay, "BY")
	) {
		setNextWorkday(nextDay);
	}
	else{
		store.state.nextWorkday = nextDay;
	} 
}

//Fehlermeldung erstellen
export function parseErrorMessage(error){
	return error.errorRequest + " - " + error.message + "  - (Status " + error.status + ") " ;
}

//Länder zusammenstellen
export function getCountryFromIso3Code(iso3Code) {
	let selectedCountry = store.state.req.countries.find(item => item.iso3 === iso3Code);

	if (selectedCountry && selectedCountry.id != 1) return selectedCountry.id;
	else return 0;
}

//Werte aus Objekt holen mit Tiefenprüfung
export function getDeepValue(item, key) {
	var deepKeys = key.split('.');

	if (deepKeys.length > 1) {
		var param1 = [deepKeys[0]];
		var param2 = [deepKeys[1]];
		if (deepKeys.length == 2) {
			if (param1 in item && param2 in item[param1]) {
				return item[param1][param2];
			} else return '';
		} else if (deepKeys.length == 3) {
			var param3 = [deepKeys[2]];
			return item[param1][param2][param3];
		}
	} else return item[key];
}

//Zusammengesetzte Werte aufschlüsseln - Mit ";" getrennte Werte aneinanderfügen
export function getComputedValues(item, keyValues) {
	let keys = keyValues.split(';');
	let computedValue = '';
	for (const index in keys) {
		computedValue += getDeepValue(item, keys[index]);
	}
	return computedValue.trim();
}

//Einträge Status zurücksetzen
export function resetStatusFilter(items) {

	if(items !=null && items.length){
		let newItems = items.map(item => {
			item.show = 1;
			//item.matches = 1;
			return item;
		});
		return newItems;
	}
	else return items;
}


//Einträge filtern 
export function filterItems(items, key, value, compareType) {
	let itemsCount = 0;
	if(items !=null && items.length){
		let newItems = items.map(item => {
			let deepValue = getComputedValues(item, key);
			//Standard ist der Vergleich auf Gleichheit
			let valueMatches = deepValue == value ? true : false;
			if (compareType == 'dategreater') {
				valueMatches = new Date(new Date(value).setHours(0, 0, 0, 0)).getTime() < new Date(deepValue)
					.getTime() ? true :
					false;
			} else if (compareType == 'datesmaller') {
				valueMatches = new Date(new Date(value).setHours(23, 59, 59, 999)).getTime() > new Date(deepValue)
					.getTime() ? true :
					false;
			} else if (compareType == 'contains') {
				//auf Ähnlichkeit prüfen
				//Klein-Großschreibung ignorieren
				if (deepValue) deepValue = deepValue.toString().toLowerCase();
				if (value) value = value.toString().toLowerCase();
				valueMatches = deepValue.indexOf(value) !== -1 ? true : false;
			} else if (compareType == 'notEquals') {
				//auf nicht Gleichheit prüfen
				valueMatches = deepValue != value ? true : false;
			} else {
				//Klein-Großschreibung ignorieren
				if (deepValue) deepValue = deepValue.toString().toLowerCase();
				if (value) value = value.toString().toLowerCase();
				valueMatches = deepValue == value ? true : false;
			}

			//&& item.statusCode != "isCancelled"
			if (item.show == 1 && valueMatches) {
				item.show = 1;
				itemsCount++;
				item.matches = 1;
			} else {
				if (!valueMatches && key != 'statusCode') item.matches = 0;
				else if (item.show == 1 && key == 'statusCode') item.matches = 1;
				item.show = 0;
			}
			return item;
		});


		store.state.serviceItemCount = itemsCount;
		//store.state.serviceItemCount = newItems.filter(item => item.show == 1).length;
		return newItems;
	}
	else return items;
}

//Status setzen
export function countStatusItems(items) {
	store.state.countByStatus = {
		isCancelled: 0,
		isClosed: 0,
		isPlanned: 0,
		isNew: 0,
		inProcess: 0
	};
	if(items){
		Object.entries(items).forEach(([key, item]) => {
			if (key && item.matches == 1) store.state.countByStatus[item.statusCode]++;
		});
	}
}

//Identische Werten entfernen
function getUniqueList(items, key) {
	return [...new Map(items.map(item => [item[key], item])).values()]
}

//Sortierfunktion für Einträge
export function sortItems(items, params) {
	let keyValues = params.key;
	let direction = params.direction.toLowerCase();
	let sortType = params.sortType;
	let keys = keyValues.split(';');
	items.sort(
		(a, b) => {
			let fa = '',
				fb = '';
			for (const index in keys) {
				fa += getDeepValue(a, keys[index]);
				fb += getDeepValue(b, keys[index]);
			}
			if (sortType == 'number') {
				if (fa.length && fb.length && !isNaN(fa) && !isNaN(fb)) {
					fa = Number(fa);
					fb = Number(fb);
				}
				if (fa.length == 0) fa = '';
				if (fb.length == 0) fb = '';

			} else if (sortType == 'date') {
				if (!fa) fa = 0;
				if (!fb) fb = 0;

				fa = new Date(fa).getTime();
				fb = new Date(fb).getTime();
			} else {
				fa = fa ? fa.toString().toLowerCase() : '';
				fb = fb ? fb.toString().toLowerCase() : '';
			}

			if (direction == 'asc') {
				if (fa < fb) return 1;
				else if (fa > fb) return -1;
				else return 0;
			} else {
				if (fa < fb) return -1;
				else if (fa > fb) return 1;
				else return 0;
			}
		}
	);
	return (items);
}

//Länder sortieren
export function sortCountriesUnique(items, params) {
	//Land unbekannt aussortieren: Erstmal dringelassen. Ich prüfe beim Senden auf ID 1 der Länder.
	//return getUniqueList(sortItems(items, params),params.key).filter(item => item.id != 1);
	return getUniqueList(sortItems(items, params), params.key);
}

//Lieferdatum nach Eintrag in Order prüfen
export function getDeliveryDateByworkUnitActivities(order,isSaleOrder){
	if(isSaleOrder===true ){
		if('deliveryDate' in order && order.deliveryDate !='' ){
			return order.deliveryDate;
		}
		else if('deliveryWeek' in order && order.deliveryWeek !=''){
			let deliveryWeek = order.deliveryWeek;
			let yearNum = deliveryWeek.substring(0,2);
			let weekNum = deliveryWeek.substring(2,deliveryWeek.length);
			//Erste Kalenderwoche Montag: So viele Tage zur Woche hinzufügen
			let firstYearWeekWed =(1 + (7 - new Date('20'+yearNum,0,1).getDay() + 1) % 7 || 0);
			return new Date('20'+yearNum, 0, (firstYearWeekWed + (weekNum - 1) * 7));
		}
	}
	if('workUnitActivities' in order){
		if('isOpen' in order && order.isOpen === true){
			let undoneOpenWorkUnits = order.workUnitActivities.filter(item => item.isDone === false);
			//wir nehmen erstmal das erste Vorkommen, oder das Neueste?? Noch zu klären
			if(undoneOpenWorkUnits.length){
				//wenn Größe > 1 gibt es mehr als einen nicht erledigten Auftrag, dann eventuell nach weiteren Kriterien filtern
				//aktuell nehme ich den ersten Eintrag.
				let deliveryInfoFound = undoneOpenWorkUnits[0];
				return deliveryInfoFound.date;
			}
			else return '';
		}
		else if('isOpen' in order && order.isOpen === false){ // Wenn Auftrag abgeschlossen
			let closedWorkUnits = order.workUnitActivities.filter(item => item.isDone === true);
			//wir nehmen erstmal das erste Vorkommen, oder das Neueste?? Noch zu klären
			if(closedWorkUnits.length){
				//wenn Größe > 1 gibt es mehr als einen nicht erledigten Auftrag, dann eventuell nach weiteren Kriterien filtern
				//aktuell nehme ich den ersten Eintrag.
				let deliveryInfoFound = closedWorkUnits[0];
				return deliveryInfoFound.date;
			}
			else return '';
		}
		else return '';
	}
	return '';
}

//Service-Items iniziieren und kalkulierte Werte setzen
export function initItems(serviceItems) {
	store.state.lastUpdate = 0;

	return serviceItems.map(item => {
		var statusCode = "isNew";
		//Prüfen ob Auftrag gelöscht
		var orderIsCancelled = false;
		if(Object.prototype.hasOwnProperty.call(item, 'isCancelled')  && item.isCancelled === true){ orderIsCancelled=true;}
		if(Object.prototype.hasOwnProperty.call(item, 'orderId') && item.orderId > 0 && Object.prototype.hasOwnProperty.call(item, 'order') && Object.prototype.hasOwnProperty.call(item.order, 'isCancelled') && item.order.isCancelled === true){
			orderIsCancelled=true;
		}
		//wer hat Auftrag gelöscht
		if (orderIsCancelled === true){
			statusCode ="isCancelled";
			if( Object.prototype.hasOwnProperty.call(item, 'cancellerId') ){
				if(Object.prototype.hasOwnProperty.call(item, 'canceller')){
					let canceller = item.canceller;
					if(canceller && typeof canceller === 'object'){
						if(Object.prototype.hasOwnProperty.call(canceller, 'firstName') && Object.prototype.hasOwnProperty.call(canceller, 'lastName')){
							item.cancellerInfo = "storniert durch: " + canceller.firstName + ' '+ canceller.lastName;
						}
						else if(Object.prototype.hasOwnProperty.call(canceller, 'userName')){
							item.cancellerInfo = "storniert durch: " +  canceller.userName;
						}
					}
					else{
						if(Object.prototype.hasOwnProperty.call(item, 'cancellerId'))
							item.cancellerInfo = "storniert durch : User mit id "+ item.cancellerId;
						else
							item.cancellerInfo = "storniert durch : unbekannt";
					}
				}
			}
			else{ item.cancellerInfo = "storniert in WaWi "; }
		}
		else if (Object.prototype.hasOwnProperty.call(item, 'orderId') && Object.prototype.hasOwnProperty.call(item, 'order')) {
			//Wenn es hinterlegte Rechnungen gibt die IDs der Rechnungen speichern.
			if(Object.prototype.hasOwnProperty.call(item, 'serviceRequestInvoiceFiles') && Array.isArray(item.serviceRequestInvoiceFiles)){
				let invoiceFiles = [];
				//Auslesen und Übergabe der IDs der Rechnungen für die Anzeige in der Detail-Ansicht
				for (let i = 0; i < item.serviceRequestInvoiceFiles.length; i++) {
					if(Object.prototype.hasOwnProperty.call(item.serviceRequestInvoiceFiles[i], 'id')){
						invoiceFiles.push(item.serviceRequestInvoiceFiles[i]['id'])
					}
				}
				if(invoiceFiles.length > 0) item.invoiceFiles = invoiceFiles.toString();
			}
			if(Object.prototype.hasOwnProperty.call(item, 'serviceRequestDeviceReportFiles') && Array.isArray(item.serviceRequestDeviceReportFiles)){
				let reportFiles = [];
				//Auslesen und Übergabe der IDs der Rechnungen für die Anzeige in der Detail-Ansicht
				for (let i = 0; i < item.serviceRequestDeviceReportFiles.length; i++) {
					if(Object.prototype.hasOwnProperty.call(item.serviceRequestDeviceReportFiles[i], 'id')){
						reportFiles.push(item.serviceRequestDeviceReportFiles[i]['id'])
					}
				}

				if(reportFiles.length > 0) item.reportFiles = reportFiles.toString();
			}
			if(Object.prototype.hasOwnProperty.call(item, 'serviceRequestDeliveryNoteFiles') && Array.isArray(item.serviceRequestDeliveryNoteFiles)){
				let deliveryFiles = [];
				//Auslesen und Übergabe der IDs der Lieferscheine für die Anzeige in der Detail-Ansicht
				for (let i = 0; i < item.serviceRequestDeliveryNoteFiles.length; i++) {
					if(Object.prototype.hasOwnProperty.call(item.serviceRequestDeliveryNoteFiles[i], 'id')){
						deliveryFiles.push(item.serviceRequestDeliveryNoteFiles[i]['id'])
					}
				}

				if(deliveryFiles.length > 0) item.deliveryFiles = deliveryFiles.toString();
			}
			//Verkaufs-Bestellungen herausfinden, bei denen wird bei abgeschlossenen Aufträgen als Lieferdatum das order.deliveryDate angezeigt.
			let isSaleOrder = false;
			//Es gibt verschiedene Auftragsarten (itemserviceRequestTypeId, die in der Warenwirtschaft aber mit dem gleichen Typ (item.order.orderTypeNumber) erfasst werden.
			//Daher werden hier die Eintragungen in der Warenwirtschaft zur Filterung der Verkaufsaufträge herangezogen
			if( 'orderTypeNumber' in item.order && item.order.orderTypeNumber === '02'){
				isSaleOrder = true;
			}
			//Lieferdatum - terminiert für bestimmmen
			item.order.deliveryDateComposed = getDeliveryDateByworkUnitActivities(item.order,isSaleOrder);

			item.order.isWeekDate = ('deliveryWeek' in item.order && item.order.deliveryWeek !='' ) ? true:false;
			//status Code bestimmen
			statusCode = "inProcess";
			if (item.order.isOpen === false) statusCode = "isClosed";
			if (Object.prototype.hasOwnProperty.call(item, 'updatedAt')) {
				//letztes Update setzen				
				if (store.state.lastUpdate < new Date(item.order.updatedAt).getTime()) {
					store.state.lastUpdate = new Date(item.order.updatedAt).getTime();
				}
			}
			item.orderNumber = item.order.number;
		}		
		item.statusCode = statusCode;
		item.show = 1;

		
		return item;
	});
}

//Verschau-Einträge für Suche generieren
export function getCompanyPreviewName(fullName, companyName1, companyName2, companyName3) {
	if (fullName) return fullName;
	else if (companyName1){
		let companyName = companyName1;
		if(companyName2) companyName = companyName + ' ' + companyName2;
		if(companyName3) companyName = companyName + ' ' + companyName3;
		return (companyName).trim();
	}
	else return '';
}

//Firmenname zusammensetzen
export function parseCompanyFromFullName(fullName, companyMaxChars) {
	let splitName = fullName.split(" ");
	var companyName = [{
		value: '',
		filled: false
	}, { 
		value: '',
		filled: false
	}, {
		value: '',
		filled: false
	}];

	splitName.forEach(word => {
		if (companyName[0].filled == false && (companyName[0].value.length + word.length) < (
				companyMaxChars - 1) && word != "Herr" && word != "Frau") {
			companyName[0].value += " " + word;
		} else if (companyName[1].filled == false && (companyName[1].value.length + word
				.length) <
			(
				companyMaxChars - 1)) {
			companyName[0].filled = true;
			companyName[1].value += " " + word;
		} else if (companyName[2].filled == false && (companyName[2].value.length + word
				.length) <
			(
				companyMaxChars - 1)) {
			companyName[1].filled = true;
			companyName[2].value += " " + word;
		} else {
			companyName[0].value = "";
			companyName[1].value = "";
			companyName[2].value = "";
		}

	});
	return {
		'companyName1': companyName[0].value.trimLeft().trim(),
		'companyName2': companyName[1].value.trimLeft().trim(),
		'companyName3': companyName[2].value.trimLeft().trim()
	};
}

//Firmenname trennen, wenn nur der zusammengesetzte Wert vorhanden ist
export function parseCompanyName(fullName, companyName1, companyName2, companyName3, companyMaxChars) {
	if (companyName1 || companyName2 || companyName3) {
		return {
			'companyName1': companyName1.trimLeft().trim(),
			'companyName2': companyName2.trimLeft().trim(),
			'companyName3': companyName3.trimLeft().trim()
		};
	} else {
		if (fullName != undefined && fullName != '') {
			return parseCompanyFromFullName(fullName, companyMaxChars);
		} else return {
			'companyName1': '',
			'companyName2': '',
			'companyName3': ''
		};
	}

}

//Adresse für Vorschauliste zusammenstellen
export function parseAddress(item) {
	if ('place' in item) {
		return (item.place ? item.place + ", " : '') + (item.street ? item.street + " " : '') + (item.streetNumber ?
			item.streetNumber + " " : '');
	} else if ('address' in item) {
		return (item.address.place ? item.address.place + ", " : '') + (item.address.street ? item.address.street +
			" " : '') + (item.address.streetNumber ? item.address.streetNumber + " " : '');

	}
}

//Highlight setzen
export function highlightSearchWord(itemWord, searchWord) {
	var regexp = new RegExp(searchWord, 'gi');
	return itemWord.trim().replace(regexp,'<span class="text-danger">' + searchWord + '</span>')+ " ";

}

//Firmennamen zusammenstellen
export function getDeliveryCustomerSearchName(item, searchWord, highlightKey) {
	let deliveryCustomerFirst = highlightKey == 'deliveryCustomerNumber' ? 1 : 0;
	let searchName = {};
	let fullString;

	if ('deliveryCustomerNumber' in item && item.deliveryCustomerNumber) {
		searchName.deliveryCustomerNumber = highlightKey == 'deliveryCustomerNumber' ? highlightSearchWord(item
			.deliveryCustomerNumber, searchWord) : item.deliveryCustomerNumber;
	}
	searchName.companyName = highlightKey == 'companyName' ? highlightSearchWord(getCompanyPreviewName(item.name, item
		.companyName1, item.companyName2, item.companyName3), searchWord) : getCompanyPreviewName(item.name, item
		.companyName1, item.companyName2, item.companyName3);

	if ('postCode' in item && item.postCode) {
		searchName.postCode = highlightKey == 'postCode' ? highlightSearchWord(item.postCode, searchWord) : item
			.postCode;
	}
	searchName.address = parseAddress(item);
	if (deliveryCustomerFirst)
		fullString = [
			searchName.deliveryCustomerNumber,
			searchName.companyName,
			searchName.postCode,
			searchName.address
		];
	else fullString = [
		searchName.companyName,
		searchName.postCode,
		searchName.address,
		searchName.deliveryCustomerNumber
	];

	return fullString.join(" ");

}

//Objekte zusammenfügen - mit Tiefenprüfung
export function assignDeepOject(target, source) {
	const isObject = (obj) => obj && typeof obj === 'object';

	if (!isObject(target) || !isObject(source)) {
		return source;
	}

	Object.keys(source).forEach(key => {
		const targetValue = target[key];
		const sourceValue = source[key];

		if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
			target[key] = targetValue.concat(sourceValue);
		} else if (isObject(targetValue) && isObject(sourceValue)) {
			target[key] = assignDeepOject(Object.assign({}, targetValue), sourceValue);
		} else {
			target[key] = sourceValue;
		}
	});

	return target;
}

//Suchliste nach Kundenname zusammensetzen
export function setCustomerNameSearchList(items, searchWord, highlightKey) {
	let newItems = items.map(item => {
		let companyName = item.companyName1;
		if(item.companyName2) companyName = companyName + item.companyName2;
		if(item.companyName3) companyName = companyName + item.companyName3;
		return {
			'id': item.id,
			'searchWord': (companyName).trim(),
			'fullName': getDeliveryCustomerSearchName(item, searchWord, highlightKey),
			'deliveryCustomerNumber': item.deliveryCustomerNumber
		};
	});

	return getUniqueList(sortItems(newItems, {
		key: 'deliveryCustomerNumber',
		direction: 'desc',
		sortType: 'string'
	}), 'fullName');

}


//Suchliste nach customerReferenceField zusammensetzen
export function setCustomerReferenceSearchList(items, searchWord) {

	let countItems = 0;
	let newItems = new Array();
	Object.values(items).forEach( value => {
			newItems.push({
				'id': countItems++,
				'searchWord': Object.keys(value)[0],
				'fullName': highlightSearchWord(Object.keys(value)[0], searchWord),
				'ids': Object.values(value)[0],
				'customerReference': Object.keys(value)[0],
			});
			countItems++;

	});


	return getUniqueList(sortItems(newItems, {
		key: 'customerReference',
		direction: 'desc',
		sortType: 'string'
	}), 'fullName');

}

//Suchliste nach Kundennummer zusammensetzen
export function setCustomerNumberSearchList(items, searchWord, highlightKey) {

	let countItems = 0;
	let newItems = items.map(item => {
		return {
			'id': countItems++,
			'searchWord': item.deliveryCustomerNumber.trim(),
			'fullName': getDeliveryCustomerSearchName(item, searchWord, highlightKey),
			'ids': [item.id],
			'deliveryCustomerNumber': item.deliveryCustomerNumber
		};
	});
	return getUniqueList(sortItems(newItems, {
		key: 'deliveryCustomerNumber',
		direction: 'desc',
		sortType: 'string'
	}), 'fullName');

}

//Suchliste nach Kundennummer zusammensetzen
export function setCustomerNumberIdsList(items, searchWord, highlightKey) {

	let countItems = 0;
	let newItems = items.map(item => {
		return {
			'id': countItems++,
			'searchWord': item.deliveryCustomerNumber.trim(),
			'fullName': getDeliveryCustomerSearchName(item, searchWord, highlightKey),
			'ids':[item.id],
			'deliveryCustomerNumber': item.deliveryCustomerNumber
		};
	});
	return getUniqueList(sortItems(newItems, {
		key: 'ids',
		direction: 'desc',
		sortType: 'string'
	}), 'fullName');

}


//Suchliste nach Kundennummer zusammensetzen
export function setInfoMachineSearchList(items,searchWord) {
	let countItems = 0;
	let newItems = new Array();

	for(const [key, value] of Object.entries(items)){
			newItems.push({
				'id': countItems++,
				'searchWord': key,
				'fullName': highlightSearchWord(key, searchWord),
				'ids': value,
				'infoMachine': key,
			});
			countItems++;


	}

	return getUniqueList(sortItems(newItems, {
		key: 'infoMachine',
		direction: 'desc',
		sortType: 'string'
	}), 'fullName');

}

//Adressliste über Polstleitzahlen-Suche zusammenstellen
export function setDeliveryCustomerSearchList(items, searchWord, highlightKey, companyMaxChars) {
	let countItems = 0;
	items.map(item => {
		item.id = countItems;
		item.fullName = getDeliveryCustomerSearchName(item, searchWord, highlightKey);
		if (!('companyName1' in item) || item.companyName1 === null || item.companyName1 =='') {
			Object.assign(item, parseCompanyFromFullName(item.name, companyMaxChars));
		}
		countItems++;

		return item;
	});
	return getUniqueList(sortItems(items, {
		key: 'fullName',
		direction: 'desc',
		sortType: 'string'
	}), 'fullName');

}

export function getTranslatedCountries(countries){
	let lang = localStorage.getItem('language');
	if(typeof(lang) === 'string') lang = lang.substring(0,2);
	//Prüfen ob Sprache vorhanden ansonsten auf Default zurücksetzen
	if(countries && typeof countries === 'object'){
		if(!Object.values(countries)[0][lang]){
			lang = 'en';
		}
	}
	if(countries){
		let sortedValues = sortCountriesUnique(countries, {
			key: lang,
			direction: 'desc',
			sortType: 'string'
		});
		return sortedValues.map(item => {
			item.translatedName = item[lang];
			return item;
		});

	}
}

export function getTranslatedServiceRequestTypes(serviceRequestTypes){
	let lang = localStorage.getItem('language');
	if(typeof(lang) === 'string') lang = lang.substring(0,2);
	//Prüfen ob Sprache vorhanden ansonsten auf Default zurücksetzen
	if(serviceRequestTypes && typeof serviceRequestTypes === 'object'){
		if(!Object.values(serviceRequestTypes)[0][lang]){
			lang = 'en';
		}
	}
	if(serviceRequestTypes && lang){
		return serviceRequestTypes.map(item => {
			item.translatedName = item[lang];
			return item;
		});
	}
}