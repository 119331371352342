<template>
  <div class="bg-light">
    <div class="container bg-white p-0">
      <nav class="bg-light p-2 ps-4 pe-4">
        <div class="row  ">
          <div class="col-8 col-sm-8 col-md-8 col-lg-8 mt-3 mb-3  ">
            <router-link class="logo" :to="{ path: '/' }">
              <img src="@/assets/img/logo.png" width="284"   alt="TriPort"/>
            </router-link>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 mt-4 d-flex align-items-center justify-content-end top-right-menu">
            <router-link to="/"><img src="@/assets/img/home.svg" alt="" width="20" class="d-inline-block align-text-top"></router-link>
            <span class="ms-2 ps-2 border border-2 border-top-0 border-bottom-0 border-end-0 pe-2x pe-7s-clock"></span><span class="p-1">&nbsp; {{ time  }}</span>
            <span class="ms-2 ps-2 border border-2 border-top-0 border-bottom-0 border-end-0  d-block p-1 ms-2 me-4">{{ $t('messages.general.selectLanguage') }}</span>
            <form>
              <select  class="language-select"  v-model="selectedLanguage" @change="changeGlobalLanguage()">
                <option v-for="(lang, i) in langs" :key="`lang${i}`" :value="lang">
                  {{ i }}
                </option>
              </select>
            </form>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>


<script>
import { getLogoutTime, getLanguage } from "@/main";


export default {
  name: "Login",
  data() {
    return {
      langs: {
        'DE':'de-DE',
        'EN':'en-GB',
        'FR':'fr-FR'
      },
      time:null,
      selectedLanguage:'de-DE'
    }
  },
  computed:{
    language(){
      return this.selectedLanguage;
    }
  },
  methods:{
    changeGlobalLanguage(){
      localStorage.setItem('language', this.selectedLanguage);
      this.$i18n.locale = this.selectedLanguage;
      /* Sprach-Enpoints neu laden */
      this.$store.commit('SET_LANGUAGE_PARAMS',{lang:this.selectedLanguage,translations:this.$t});
    }
  },
  created() {
    this.selectedLanguage = getLanguage();
    this.$i18n.locale = this.selectedLanguage;
    this.$store.commit('SET_LANGUAGE_PARAMS',{lang:this.selectedLanguage,translations:this.$t});
    // update the time every second
    if (getLogoutTime()) {
      setInterval(() => {
        if(getLogoutTime() - new Date() > 0){
          this.time = new Date(getLogoutTime() - new Date()).toLocaleTimeString(this.$i18n.locale, {timeZone: 'UTC'});
        }
        else{
          window.location.reload();
        }
      }, 1000);

    }
    else{
      this.time = 0;
    }
  }
}
</script>

<style>
.language-select {
  padding: 0;
}
</style>
