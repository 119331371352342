import ServiceRequests from '../services/request.queries.js';
import * as helpers from '../helpers'
import {getTranslatedCountries, getTranslatedServiceRequestTypes} from "../helpers";

//Definiert werden global
//Länder, Auftragsart(Request-Types), Ansprechpartner, FehlerMeldungen, StatusMeldungen
//Infos zu Liste und Detail: Welche Parameter wurden gerade angefragt, wegen reload
const initialState = {
	countries: null,
	serviceRequestTypes: null,
	translations:null,
	usedSubsidiaries: null,
	userList: null,
	language:'de',
	status: {
		responseAwait: '',
		responseAnswer: '',
		responseAction: '',
		uploadProgress: 0,
		errorMessage: '',
		successMessage:'',
		list: {
			loaded: false,
			params: null,
			limit: 30,
			pageOrdId: 0,
			pageOrdIdDir: 'DESC',
			pageSortKey: 'createdAt'
		},
		detail: {
			loaded: false,
			params: null
		},
		address: {
			statuscode: null,
			params: null
		},
		addresslist: {
			statuscode: null,
			params: null
		}
	}
};

function setAwait(state, isWaiting) {
	if (isWaiting) {
		state.status.responseAwait = true;
		state.status.responseError = false;		
	} else {
		state.status.responseAwait = false;
	}
	
}

export const req = {
	namespaced: true,
	state: initialState,
	getters: {
		countries: (state) => state.countries,
		serviceRequestTypes: (state) => state.serviceRequestTypes,
		usedSubsidiaries: (state) => state.usedSubsidiaries,
		userList: (state) => state.userList,
		responseAwait: (state) => state.status.responseAwait,
		responseStatus: (state) => state.status.responseStatus,
		responseAnswer: (state) => state.status.responseAnswer,
		responseAction: (state) => state.status.responseAction,
		responseError: (state) => state.status.responseError,
	},
	actions: {
		getPlaceNamesByPostalCode({commit},params) {
			commit('AWAIT_ANSWER', true);
			//Endpoint: https://zip-api.eu/api/v1/info/[iso2_country_code]-[postal_code]
			return ServiceRequests.getPlaceNamesByPostalCode(params).then(
				response => {
					commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest = req.state.translations("messages.reqErrors.zipApiError");
						commit('QUERY_FAILURE', {
							'error': response
						});
					}
					return Promise.resolve(response);
				}
			);
		},
		getPostalCodesByPlaceName({commit},params) {
			commit('AWAIT_ANSWER', true);
			//Endpoint: https://zip-api.eu/api/v1/info/[iso2_country_code]-[postal_code]
			return ServiceRequests.getPostalCodesByPlaceName(params).then(
				response => {

					commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest = req.state.translations("messages.reqErrors.zipApiError");
						commit('QUERY_FAILURE', {
							'error': response
						});
					}
					return Promise.resolve(response);
				}
			);
		},
		detail({
			commit
		},params) {
			commit('AWAIT_ANSWER', true);
			return ServiceRequests.getServiceItem(params).then(
				response => {
					commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest = req.state.translations("messages.reqErrors.getServiceItem");
						commit('QUERY_FAILURE', {
							'queryParams': params,
							'error': response
						});
					}
					return Promise.resolve(response);
				}
			);
		},
		getInvoiceFiles({
			commit
						}, id, params) {
			commit('AWAIT_ANSWER', true);
			return ServiceRequests.getInvoiceFiles(id, params).then(
				response => {
					commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest = req.state.translations("messages.reqErrors.getServiceItem");
						commit('QUERY_FAILURE', {
							'queryParams': params,
							'error': response
						});
					}
					return Promise.resolve(response);
				}
			);
		},
		getReportFiles({
							commit
						}, idParams, params) {
			commit('AWAIT_ANSWER', true);
			let serviceId,reportFileId;
			if(Object.prototype.hasOwnProperty.call(idParams, 'serviceId'))
				serviceId = idParams.serviceId;
			if(Object.prototype.hasOwnProperty.call(idParams, 'reportFileId'))
				reportFileId = idParams.reportFileId;
			if(serviceId && reportFileId){
				return ServiceRequests.getReportFiles(serviceId,reportFileId, params).then(
					response => {
						commit('AWAIT_ANSWER', false);
						if (response.error) {
							response.errorRequest =  req.state.translations("messages.reqErrors.getServiceItem");
							commit('QUERY_FAILURE', {
								'queryParams': params,
								'error': response
							});
						}
						return Promise.resolve(response);
					}
				);
			}
			else{
				commit('AWAIT_ANSWER', false);
				let errorMessage = {'errorRequest':req.state.translations("messages.reqErrors.getReportFilesErrorRequest"),'status':'noRequest','error': true, 'message': req.state.translations("messages.reqErrors.getReportFilesErrorMessage")}
				commit('QUERY_FAILURE', {
					'queryParams': params,
					'error': errorMessage
				});
				return errorMessage;
			}
		},
		getDeliveryFiles({commit}, idParams, params) {
			commit('AWAIT_ANSWER', true);
			let serviceId;
			if(Object.prototype.hasOwnProperty.call(idParams, 'serviceId'))
				serviceId = idParams.serviceId;
			if(serviceId){
				return ServiceRequests.getDeliveryFiles(serviceId,params).then(
					response => {
						commit('AWAIT_ANSWER', false);
						if (response.error) {
							response.errorRequest = req.state.translations("messages.reqErrors.getDeliveryFiles");
							commit('QUERY_FAILURE', {
								'queryParams': params,
								'error': response
							});
						}
						return Promise.resolve(response);
					}
				);
			}
			else{
				commit('AWAIT_ANSWER', false);
				let errorMessage = {'errorRequest':req.state.translations("messages.reqErrors.getDeliveryFilesErrorRequest"),'status':'noRequest','error': true, 'message': req.state.translations("messages.reqErrors.getDeliveryFilesErrorMessage")}
				commit('QUERY_FAILURE', {
					'queryParams': params,
					'error': errorMessage
				});
				return errorMessage;
			}
		},
		countries({
			commit
		}, params) {
			commit('AWAIT_ANSWER', true);
			return ServiceRequests.getCountries(params).then(
				response => {
					commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest = req.state.translations("messages.reqErrors.countries");
						commit('QUERY_FAILURE', {
							'queryParams': params,
							'error': response
						});
						return Promise.resolve(response);
					} else {
						commit('COUNTRIES_QUERY', response.data);
						return Promise.resolve({error:false});
					}
				}
			);			
		},
		serviceRequestTypes({
			commit
		}) {
			commit('AWAIT_ANSWER', true);
			return ServiceRequests.getServiceRequestTypes().then(
				response => {
					commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest = req.state.translations("messages.reqErrors.serviceRequestTypes");
						commit('QUERY_FAILURE', {
							'error': response
						});
						return Promise.resolve(response);
					} else {
						commit('SERVICE_REQUEST_TYPES_QUERY', response.data);
						return Promise.resolve({error:false});
					}
				}
			);
		},
		usedSubsidiaries({
			commit
		}) {
			commit('AWAIT_ANSWER', true);
			return ServiceRequests.getSubsidiariesList().then(
				response => {
					commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest =  req.state.translations("messages.reqErrors.serviceRequestTypes");
						commit('QUERY_FAILURE', {
							'error': response
						});
						return Promise.resolve(response);
					} else {
						commit('SUBSIDIARIES_QUERY', response.data);
						return Promise.resolve({error:false});
					}
				}
			);
		},
		clerkList({commit}, params) {
			commit('AWAIT_ANSWER', true);
			return ServiceRequests.getClerkList(params).then(
				response => {
					commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest = req.state.translations("messages.reqErrors.clerkList");
						commit('QUERY_FAILURE', {
							'error': response
						});
						//DEBUG
						//console.log(response);
					}
					return Promise.resolve(response);
				}
			);
		},
		userList({
			commit
		}) {
			commit('AWAIT_ANSWER', true);
			return ServiceRequests.getUserList().then(
				response => {
					commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest =  req.state.translations("messages.reqErrors.userList");
						commit('QUERY_FAILURE', {
							'error': response
						});
						return Promise.resolve(response);
					} else {
						commit('USER_QUERY', response.data);
						return Promise.resolve({error:false});
					}
				}
			);
		},
		listServiceItems({
			commit
		}, params) {

			//params.orderDir = req.state.status.list.orderDir;
			params.limit = req.state.status.list.limit;
			if(Object.prototype.hasOwnProperty.call(params, 'pageOrdId')){
				req.state.status.list.pageOrdId = params.pageOrdId;
			}
			if(req.state.status.list.pageOrdId > 0)
				params.pageOrdId = req.state.status.list.pageOrdId;
			else if(Object.prototype.hasOwnProperty.call(params, 'pageOrdId')) delete params.pageOrdId;
			commit('AWAIT_ANSWER', true);
			return ServiceRequests.getServiceItemsList(params).then(
				response => {
					commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest = "Fehler beim Laden der ServiceAufträge.";
						commit('QUERY_FAILURE', {
							'error': response
						});
					}
					return Promise.resolve(response);
				}
			);
		},
		getDeliveryCustomersByNumber({
			commit
		}, searchstring) {
			//commit('AWAIT_ANSWER', true);
			return ServiceRequests.getDeliveryCustomersByNumber(searchstring).then(
				response => {
					//commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest = "Fehler beim Laden der Adressen über die Kundennummer.";
						commit('QUERY_FAILURE', {
							'error': response
						});
					} 
					return Promise.resolve(response);
				}
			);
		},
		getServiceItemsByInfoMachine({commit}, searchstring) {
			//commit('AWAIT_ANSWER', true);
			return ServiceRequests.getServiceItemsByInfoMachine(searchstring).then(
				response => {
					if (response.error) {
						response.errorRequest = "Fehler beim Laden der Adressen über die Kundennummer.";
						commit('QUERY_FAILURE', {
							'error': response
						});
					}
					return Promise.resolve(response);
				}
			);
		},
		getServiceItemsByCustomerReference({commit}, searchstring) {
			return ServiceRequests.getServiceItemsByCustomerReference(searchstring).then(
				response => {
					if (response.error) {
						response.errorRequest = "Fehler beim Laden der Adressen über die Kundennummer.";
						commit('QUERY_FAILURE', {
							'error': response
						});
					}
					return Promise.resolve(response);
				}
			);
		},
		getAddressListByPostCode({
			commit
		}, params) {
			//commit('AWAIT_ANSWER', true);
			return ServiceRequests.getAddressListByPostCode(params).then(
				response => {
					//commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest = "Fehler beim Laden der Adressen über die Postleitzahl.";
						commit('QUERY_FAILURE', {
							'error': response
						});
					} 
					return Promise.resolve(response);
				}
			);			
		},
		getSubsidiariesByDistance({
			commit
		}, params) {
			//Der Ladebalken wird schon im Formular gezeigt, hier nicht zeigen
			//commit('AWAIT_ANSWER', true);
			return ServiceRequests.getSubsidiariesByDistance(params).then(
				response => {
					//commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest = req.state.translations("messages.reqErrors.getSubsidiariesByDistance");
						commit('QUERY_FAILURE', {
							'error': response
						});
					} 
					return Promise.resolve(response);
				}
			);
		},
		cancelServiceItem({
			commit
		}, params) {
			commit('AWAIT_ANSWER', true);
			return ServiceRequests.cancelServiceItem(params).then(
				response => {	
					commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest = "Fehler beim Stornieren des Service-Auftrags.";
						commit('QUERY_FAILURE', {
							'error': response
						});
					} 
					else {
						commit('SERVICE_ITEM_CANCELLED_SUCCESS', response);
					}
					return Promise.resolve(response);
				}
			);
		},
		postServiceItem({
			commit
		}, data) {
			commit('AWAIT_ANSWER', true);
			return ServiceRequests.postServiceItem(data).then(
				response => {	
					commit('AWAIT_ANSWER', false);
					if (response.error) {
						response.errorRequest = "Fehler beim Anlegen des Service-Auftrags.";
						commit('QUERY_FAILURE', {
							'error': response
						});
					} 
					else {
						commit('SERVICE_ITEM_POSTED_SUCCESS', response);
					}
					return Promise.resolve(response);
				}
			);
		},
	},
	mutations: {
		AWAIT_ANSWER(state, isWaiting) {
			setAwait(state, isWaiting);
			
		},
		COUNTRIES_QUERY(state, countrylist) {
			state.countries = getTranslatedCountries(countrylist);
		},
		SERVICE_ITEM_POSTED_SUCCESS(state){
			state.status.successMessage = req.state.translations("messages.reqInfo.submitSuccessOrder");
		},
		SERVICE_ITEM_CANCELLED_SUCCESS(state){
			state.status.successMessage = req.state.translations("messages.reqInfo.submitSuccessCancel");
			
		},
		SERVICE_REQUEST_TYPES_QUERY(state, serviceRequestTypes) {
			state.serviceRequestTypes = getTranslatedServiceRequestTypes(serviceRequestTypes);
		},
		SUBSIDIARIES_QUERY(state, subsidiariesList) {
			state.usedSubsidiaries = subsidiariesList;
		},
		USER_QUERY(state, userList) {
			state.userList = userList;
		},
		QUERY_FAILURE(state, params) {

			if (params['queryType']) {
				state.status[params['queryType']] = params['queryParams'];
			}
			state.status.errorMessage = helpers.parseErrorMessage(params['error']);
			state.status.responseError = true;
			setAwait(state, false);

		}
	}
};
