<template>

	<div class="container-fluid bg-primary text-white ps-4 pe-4 main-menu-info">
		<div class="row">
			<div class="col-md-auto p-0 ">
				<router-link to="/detail/new" class="p-4 me-2  router-link text-white text-decoration-none">
					{{ $t('messages.topnav.new') }}</router-link>
			</div>
			<div class="col-md-auto  p-0  ">
				<router-link to="/list/opened" class="p-4 me-2 ms-2 router-link text-white text-decoration-none">
					{{ $t('messages.topnav.opened') }}
				</router-link>
			</div>
			<div class="col-md-auto  p-0">
				<router-link to="/list/closed" class="p-4 router-link text-white text-decoration-none">
					{{ $t('messages.topnav.closed') }}
				</router-link>
			</div>
			<div v-if="showLayoutSwitcher" class="col-md-auto  pb-2  change-display-type d-flex justify-content-end">
				<router-link to="/list/dashboard"  class="p-0  bg-transparent"><span class="p-2 pt-0 cursor-hand" :class="{'active':!isListLayout}">
					<svg id="Icon_Dashboard" data-name="Icon Dashboard" xmlns="http://www.w3.org/2000/svg" width="28"
						height="28" viewBox="0 0 28 28">
						<g id="Boundary" fill="none" stroke="#fff" stroke-width="1">
							<rect width="28" height="28" stroke="none" />
							<rect x="0.5" y="0.5" width="27" height="27" fill="none" />
						</g>
						<path id="Icon_material-dashboard" data-name="Icon material-dashboard"
							d="M4.5,15.262h8.61V4.5H4.5Zm0,8.61h8.61V17.415H4.5Zm10.762,0h8.61V13.11h-8.61Zm0-19.372v6.457h8.61V4.5Z"
							transform="translate(-0.5 -0.5)" fill="#fff" />
					</svg>
				</span>
				</router-link>
				<router-link to="/list/opened"  class="p-0 bg-transparent" >
				<span class="p-2 pt-0 cursor-hand" :class="{'active':isListLayout}" @click="setListLayout(true)">
					<svg id="Icon_Liste" data-name="Icon Liste" xmlns="http://www.w3.org/2000/svg" width="28"
						height="28" viewBox="0 0 28 28">
						<path id="Path_format-list-text" data-name="Path / format-list-text"
							d="M2,14H8v6H2M16,8H10v2h6M2,10H8V4H2m8,0V6H22V4M10,20h6V18H10m0-2H22V14H10"
							transform="translate(2 2)" fill="#65a5d0" />
						<g id="Boundary" fill="none" stroke="#fff" stroke-width="1">
							<rect width="28" height="28" stroke="none" />
							<rect x="0.5" y="0.5" width="27" height="27" fill="none" />
						</g>
					</svg>
				</span>
				</router-link>
			</div>
		</div>
	</div>


</template>

<script>
	export default {
		props: ['showLayoutSwitcher'],
		computed: {
			isListLayout() {
				
				return this.$store.state.listLayout;
			}
		},
		methods: {
			setListLayout(isListLayout) {
				this.$store.state.listLayout = isListLayout;
			}
		}
	}
</script>

<style>
</style>
