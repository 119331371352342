<template>
	<div class="outer-content bg-light">
		<div class="header">
			<AppHeader />
		</div>
		<div class="main-content">
			<div class="container bg-white pb-2  ps-0 pe-0" v-if="showUserInfo">
				<AppUserInfo />
				
			</div>
			<div class="container bg-white pb-2  ps-0 pe-0" v-if="showAppTopNav">
				<AppTopNav :showLayoutSwitcher="showLayoutSwitcher" />
			</div>
			<router-view v-on:onAxiosError="showAxiosError" v-on:onAxiosSend="showAxiosSend"
				v-on:onSendCancelItem="showCancelItem" :updateListView="triggerUpdateList" />
		</div>

		<!-- Daten laden Info -->
		<div v-if="waitForAnswer" class="position-fixed top-0 start-50">
			<div class="loading-info"><span class="spinner-border spinner-border-sm"></span> Lade Daten ...</div>
		</div>
		<!-- Info, wenn Fehler auftragen -->
		<div id="toast-message" class="toast position-fixed top-50 start-50 translate-middle p-3" aria-atomic="true" role="alert">
			<div class="toast-header bg-danger text-white">
				<strong class="me-auto">{{ $t("messages.reqErrors.anErrorOccured") }}</strong>
				<button type="button" class="btn-close" @click="hideToastMessage()"></button>
			</div>
			<div class="toast-body bg-white ">
				<div v-if="errorMessage">
					<div v-html="errorMessage"> </div>
				</div>
			</div>
		</div>

		<!-- Info, wenn Datensatz speichert für beide - sowohl Auftrag anlegen als auch stornieren. -->
		<div class="modal fade" id="modal-message" tabindex="-1" aria-labelledby="item-send-label" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="item-send-label">
							<div v-if="modalModus==='createItem'">
								<div v-if="waitForAnswer">{{ $t("messages.reqInfo.newEntry") }} </div>
								<div v-if="!waitForAnswer && successMessage">Neuer Auftrag wurde erstellt.</div>
								<div v-if="errorMessage">{{ $t("messages.reqErrors.newEntryError") }} </div>
							</div>
							<div v-if="modalModus==='cancelItem'">
                {{ $t('messages.list.cancelOrder') }}
							</div>
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div v-if="waitForAnswer">
							<span class="spinner-border spinner-border-lg"> </span>
              {{ $t("messages.reqInfo.sendingData") }}
								
							<br />
							<div class="progress-bar progress-bar-info" role="progressbar"
								:aria-valuenow="uploadProgress" aria-valuemin="0" aria-valuemax="100"
								:style="{ width: uploadProgress + '%' }">
								{{uploadProgress}}%
							</div>
						</div>
						<div v-if="!waitForAnswer">
							<div v-if="modalModus==='createItem'">
								<div class="success-message" v-if="successMessage">
									<div class="row">
										<div class="col-lg-12">
											<p>{{successMessage}}</p>
											<p>{{ $t("messages.reqInfo.chooseTodo") }} </p>
										</div>
										<div class="col-6">
											<router-link to="/detail/new" @click="hideModalMessage()"
												class="btn btn-secondary text-white">
                        {{ $t("messages.reqInfo.createNewEntry") }} </router-link>
										</div>
										<div class="col-6">
											<router-link :to="{ path: '/list/all', query: { reload: true }}" class="btn btn-secondary text-white">
                        {{ $t("messages.reqInfo.gotoListView") }} </router-link>
										</div>
									</div>
								</div>
							</div>
							<div v-if="modalModus==='cancelItem'">

								<div class="info-message text-center"  v-if="!successMessage && cancelItem.canBeCancelled  && !errorMessage && !cancelItem.mustCallForCancel">
											<p>{{ $t("messages.reqInfo.reallyCancel") }} </p>
											<a class="btn btn-primary" @click="reallyCancelItem()">{{ $t("messages.reqInfo.cancelItem") }}</a>
								</div>
								<div class="success-message  text-center" v-if="successMessage && !errorMessage && !cancelItem.mustCallForCancel">
									<div class="row">
										<div class="col-lg-12">
											<p>{{successMessage}}</p>
										</div>
									</div>
								</div>
                <div class="info-message text-center"  v-if="!successMessage && !errorMessage && cancelItem.emergencyCancelActive">
                  <p>{{ $t("messages.reqInfo.reallyCancel") }}</p>
                  <a class="btn btn-danger" @click="reallyCancelItem()">{{ $t('messages.list.cancelOrder') }} (emergencyCancel)</a>
                </div>
								<div v-if="cancelItem.mustCallForCancel">
                  {{ $t('messages.list.alreadyTerminated') }} <br />
									{{cancelItem.subsidiaryName}} - {{cancelItem.subsidiaryCity}}
									<br />
									<span v-if="cancelItem.subsidiaryEmail"><a
											:href="'mailto:'+cancelItem.subsidiaryEmail">
											{{cancelItem.subsidiaryEmail}} </a></span>
									// <span v-if="cancelItem.subsidiaryPhone"> Tel:
										{{cancelItem.subsidiaryPhone}}</span>
								</div>
							</div>
							<div class="error-message" v-if="errorMessage" v-html="errorMessage">
							</div>
						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary text-white"
							data-bs-dismiss="modal">{{ $t('messages.general.close') }}</button>
					</div>
				</div>
			</div> 
		</div>

		<div class="footer">
			<AppFooter />
		</div>
	</div>


</template>

<script>
	import AppHeader from "./components/AppHeader.vue";
	import AppFooter from "./components/AppFooter.vue";
	import AppUserInfo from "./components/AppUserInfo.vue";
	import AppTopNav from "./components/AppTopNav.vue";
	import {
		Toast,
		Modal
	} from 'bootstrap';
  //import store from "@/store";
	//import * as progressViewer from './helpers/uploadProgress.js';

	export default {
		name: 'App',
		components: {
			AppHeader,
			AppFooter,
			AppUserInfo,
			AppTopNav
		},
		data() {
			return {
				showUserInfo: false,
				showAppTopNav: false,
				showLayoutSwitcher: false,
				toastContainer: null,
				modalContainer: null,
				modalModus: '',
				cancelItem: null,
				triggerUpdateList: false,

			}
		},
		computed: {
			uploadProgress() {
				return this.$store.state.req.status.uploadProgress;
			},
			storedCounter() {
				return this.$store.state.auth.counter;
			},
			waitForAnswer() {
				return this.$store.state.req.status.responseAwait;
			},
			errorMessage() {
        if(this.$store.state.req.status.errorMessage !==''){
          return this.$store.state.req.status.errorMessage + '<br />'+new Date().toLocaleString();
        }
        else return this.$store.state.req.status.errorMessage;
			},
			successMessage() {
				//return "Success";
				//return this.$store.getters['req/successMessage'];
				return this.$store.state.req.status.successMessage;
			}
		},
		beforeCreate() {

		},
		methods: {
			//autohide: a,delay:d
			showToastMessage(a, d) {
        if (!this.modalContainer){
          this.toastContainer = new Toast(
              document.getElementById('toast-message'), {
                autohide: a,
                delay: d
              });
          this.toastContainer.show();
        }
			},
			hideToastMessage() {
				if (this.toastContainer)
					this.toastContainer.hide();
			},
			showModalMessage() {
				if (!this.modalContainer)
					this.modalContainer = new Modal(document.getElementById('modal-message'));
				this.modalContainer.show();
			},
			hideModalMessage() {
				if (this.modalContainer)
					this.modalContainer.hide();
			},
			//Fehlermeldung wird normalerweise über den store gehandlet
			showAxiosError() {
				this.showToastMessage(false, 30000);
			},
			//Info beim Daten senden im Popup
			showAxiosSend() {
				this.resetMessages();
				this.modalModus = 'createItem';
				this.showModalMessage();
			},
			resetMessages() {
				this.$store.state.req.status.errorMessage = '';
				this.$store.state.req.status.successMessage = '';
			},
			showCancelItem(params) {
				this.resetMessages();
				this.cancelItem = params.item;
				this.modalModus = 'cancelItem';
				this.showModalMessage();
			},
			reallyCancelItem() {
				//this.resetMessages();
				this.triggerUpdateList = false;
				this.$store.dispatch("req/cancelServiceItem", {'serviceId':this.cancelItem.id,'cancellerId':this.$store.state.auth.user.userId}).then(
					(response) => {
						this.successDataMessage = this.successMessage;
						if (response.error === false) {
							this.triggerUpdateList = true;
						}
					});
			},

		},
		watch: {
			'$route': function(to, from) {

        if(this.$route.query && this.$route.query.reload) {
          this.$store.state.reload = true;
        }
        else this.$store.state.reload = false;

				document.title = to.meta.title;
				if (from.name === 'Edit' && to.name === 'New') {
					this.$router.go(0)
				}
				this.$store.state.routeName = to.name
				this.showUserInfo = to.meta.showUserInfo;
				this.showAppTopNav = to.meta.showAppTopNav;
				if (to.meta.showLayoutSwitcher) {
					this.showLayoutSwitcher = to.meta.showLayoutSwitcher;
				}
				this.hideToastMessage();
				this.hideModalMessage();
			},
		}
	}
</script>

<style lang="scss">
	@import '@/assets/scss/bootstrap';
</style>
