import axios from "axios";
import { updateToken } from "@/main";

const API_URL = process.env.VUE_APP_REQUEST_URL;

const authAxios = axios.create({
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

authAxios.defaults.baseURL = API_URL;
authAxios.interceptors.request.use(
	async (config) => {
		let newToken = await updateToken();
		config.headers.Authorization = `Bearer ${newToken}`;
		return config;
	},
	function (error) {
		//Fehlerbehandlung wird nach der Abfrage gemacht
		//alert('An error occurred. Please try again later.')
		//return Promise.reject(error);
	}
);

authAxios.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response) {
			if (error.response.status === 401) {
				// Redirect to login page
				router.push('/')
			} else {
				//Fehlerbehandlung wird nach der Abfrage gemacht
				// Show a generic error message
				//TODO Fehler zur genaueren Fehleranzeige weiterleiten
				//alert('An error occurred. Please try again later.')
			}
		}
		//Fehlerbehandlung wird nach der Abfrage gemacht
		//alert('An error occurred. Please try again later.')
		//return Promise.reject(error)
	},
);

export default authAxios;
