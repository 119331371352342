import { createI18n } from 'vue-i18n'
import enMessages from '../lang/en';
import deMessages from '../lang/de';
import frMessages from '../lang/fr';

const messages = {
  'de-DE': deMessages,
  'en-GB': enMessages,
  'fr-FR': frMessages,
}


const i18n = createI18n({
  legacy: false,
  locale: 'de-DE',
  fallbackLocale: 'en-GB',
  globalInjection: true,
  messages
})

export default i18n