import {
	createRouter,
	createWebHistory
} from 'vue-router'

//import VueRouter from 'vue-router';
import store from '../store'

const routes = [{
		path: "/",
		name: "Home",
		component: () => import('../views/Home.vue'),
		//props:{showUserInfo:false,showAppTopNav:false},
		meta:{
			"title":"Triport Home",
			"showUserInfo":false,
			"showAppTopNav":false,
			"showLayoutSwitcher":false
		}
	},
	{
		path: "/logout",
		name:"Logout",
		component: () => import('../views/Logout.vue'),
		//props:{showUserInfo:false,showAppTopNav:false},
		meta:{
			"title":"Triport Logout",
			"showUserInfo":false,
			"showAppTopNav":false,
			"showLayoutSwitcher":false
		}
	},
	{
		path: "/list/dashboard",
		name:"Dashboard",
		component: () => import('../views/List.vue'),
		//props:{showUserInfo:true,showAppTopNav:true},
		meta:{
			"title":"Triport Dashboard",
			"showUserInfo":true,
			"showAppTopNav":true,
			"showLayoutSwitcher":true
		}
	},
	{
		path: "/list/all",
		name:"ListAll",
		component: () => import('../views/List.vue'),
		//props:{showUserInfo:true,showAppTopNav:true},
		meta:{
			"title":"Triport Alle Service-Aufträge",
			"showUserInfo":true,
			"showAppTopNav":true,
			"showLayoutSwitcher":true
		}
	},
	{
		path: "/list/closed",
		name:"ListClosed",
		component: () => import('../views/List.vue'),
		//props:{showUserInfo:true,showAppTopNav:true},
		meta:{
			"title":"Triport Abgeschlossene Service-Aufträge",
			"showUserInfo":true,
			"showAppTopNav":true,
			"showLayoutSwitcher":true
		}
	},
	{
		path: "/list/opened",
		name:"ListOpened",
		component: () => import('../views/List.vue'),
		//props:{showUserInfo:true,showAppTopNav:true},
		meta:{
			"title":"Triport Aktuelle Service-Aufträge",
			"showUserInfo":true,
			"showAppTopNav":true,
			"showLayoutSwitcher":true
		}
	},
	{
		path: "/detail/new",
		name:"New",
		component: () => import('../views/Detail.vue'),
		//props:{showUserInfo:true,showAppTopNav:false},
		meta:{
			"title":"Triport Neuen Service-Auftrag erfassen",
			"showUserInfo":true,
			"showAppTopNav":true,
			"showLayoutSwitcher":false
		}
	},
	{
		path: "/detail/:id",
		name:"Edit",
		component: () => import('../views/Detail.vue'),
		props: true,
		//props:{showUserInfo:true,showAppTopNav:false},
		meta:{
			"title":"Triport Datensatz bearbeiten",
			"showUserInfo":true,
			"showAppTopNav":true,
			"showLayoutSwitcher":false
		}
	},
	{
		path: "/imprint",
		name:"Imprint",
		props: true,
		component: () => import('../views/FooterImprint.vue'),
		meta:{
			"title":"Triport Impressum",
			"showUserInfo":false,
			"showAppTopNav":false,
			"showLayoutSwitcher":false
		}
	},
	{
		path: "/dataprotection",
		name:"DataProtection",
		props: true,
		component: () => import('../views/FooterDataprotection.vue'),
		meta:{
			"title":"Triport Datenschutz",
			"showUserInfo":false,
			"showAppTopNav":false,
			"showLayoutSwitcher":false
		}
	},
	{
		path: "/agb",
		name:"AGB",
		props: true,
		component: () => import('../views/FooterAgb.vue'),
		meta:{
			"title":"Triport AGB",
			"showUserInfo":false,
			"showAppTopNav":false,
			"showLayoutSwitcher":false
		}
	},
	{
		path: "/:catchAll(.*)",
		name:"Error",
		component: () => import('../views/NotFound.vue'),
		//props:{showUserInfo:false,showAppTopNav:false},
		meta:{
			"title":"Triport Seite nicht gefunden",
			"showUserInfo":false,
			"showAppTopNav":false
		}
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});



export default router
