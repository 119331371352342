import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./lang/i18n.js"


/*CSS und Schriften importieren */
import '@/assets/fonts/roboto/roboto.css';
import '@/assets/fonts/pe-icon-7-stroke/pe-icon-7-stroke.css';
import '@/assets/fonts/pe-icon-7-stroke/helper.css';

/*Botstrap Javascript - SCSS wird in App.vue geladen*/
import 'bootstrap/dist/js/bootstrap.js'


import {
	Tooltip
} from 'bootstrap/dist/js/bootstrap.esm.min.js'




const initOptions = {
	url: process.env.VUE_APP_AUTH_URL,
	realm: process.env.VUE_APP_AUTH_REALM,
	clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
};

import Keycloak from "keycloak-js";
const keycloak = new Keycloak(initOptions);

const globalUserInfo = {
	time: 0,
	lang: 'de-DE'
};


function instantiateVueApp () {
	const app = createApp(App);
	app.directive('tooltip', {
		// When the bound element is mounted into the DOM...
		mounted(el) {
			new Tooltip(el, {
				html: true,
				placement: 'right'
			});
		}
	});



	//Sprache setzen, die aus Session gelesen wurde, falls eine andre als die Standard-Sprache gewählt war
	i18n.locale = globalUserInfo.lang;
	//store.commit('SET_LANGUAGE_PARAMS',{lang:i18n.locale.substring(0,2),translations:i18n.t});
	app.use(store);
	app.use(i18n);
	app.use(router);
	app.config.globalProperties.myKeycloak = keycloak;

	app.mount('#app');

}

export function getLogoutTime(){
	return globalUserInfo.time;
}

export function getLanguage(){
	return globalUserInfo.lang;
}


export function setGlobalUserInfo(expires){
	if(localStorage.getItem('language')){
		globalUserInfo.lang= localStorage.getItem('language');
	}
	else if(i18n && Object.hasOwnProperty(i18n,'locale')){
		globalUserInfo.lang = i18n.locale;
		localStorage.setItem('language', globalUserInfo.lang);
	}
	else{
		globalUserInfo.lang = 'de-DE';
		localStorage.setItem('language', globalUserInfo.lang);
	}
	if(!localStorage.getItem('user')){
		setUserStorage();
	}
	globalUserInfo.time = expires;
}

function setUserStorage(){
	let userData = keycloak.idTokenParsed;
	let userDataPerms =  parseJwt(keycloak.token);
	let refreshToken = parseJwt(keycloak.refreshToken);
	if (keycloak.token && keycloak.idTokenParsed) {
		let customerNumbers = 0;
		let emergencyCancel = false;
		if (Object.prototype.hasOwnProperty.call(userDataPerms, 'op') && Object.prototype.hasOwnProperty.call(emergencyCancel, 'userDataPerms.op')) {
			emergencyCancel = true;
		}
		if (Object.prototype.hasOwnProperty.call(userData, 'cus')) {
			customerNumbers = userData.cus;
		}
		let storageItem = {
			userName: userData.name,
			userEmail: userData.email,
			userId: userData.sub,
			customerNumbers: customerNumbers,
			emergencyCancel: emergencyCancel,
			accessToken: keycloak.token,
			expires: refreshToken.exp * 1000,
			organizationId: userData.organizationId,
			timeZone:  userData.timeZone
		};
		localStorage.setItem('user', JSON.stringify(storageItem));
	}

}
export function setAuthData(){
		setUserStorage();
		let refreshToken = parseJwt(keycloak.refreshToken);
		setGlobalUserInfo(refreshToken.exp * 1000);
}

export async function forceUpdateToken(){
	await keycloak.updateToken(-1);
	let refreshToken = parseJwt(keycloak.refreshToken);
	setGlobalUserInfo(refreshToken.exp * 1000);

}

export async function updateToken(){
	await keycloak.updateToken();
	let refreshToken = parseJwt(keycloak.refreshToken);
	setGlobalUserInfo(refreshToken.exp * 1000);
	return keycloak.token;
}



export default keycloak;
export async function authenticateAgainstKeycloak () {

	await keycloak.init({
		onLoad: 'login-required',
		checkLoginIframe: false,
		locale: i18n.locale,
		enableLogging: true,
		pkceMethod: "S256"
	}).then((auth) => {
		if (!auth) {
			window.location.reload()
		} else {
			setAuthData();
		}
	})
}

authenticateAgainstKeycloak().then(() => {
	instantiateVueApp();
});


function parseJwt(token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonDecoded = decodeURIComponent(atob(base64).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonDecoded);
}